import React, { useContext } from 'react';
import { Box, SxProps, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { SelectableItem, Sidebar, FiltersCount } from 'components';
import { Operator, MinifiedTeam, StatusCounters } from 'types';
import { GridSettingsContext } from 'context';

import GeneralFilters from './general-filters';
import StatusesFilter from './statuses-filter';
import TagsFilter from './tags-filter';
import AdvancedFilters from './advanced-filters';
import FiltersBySources from './filters-by-sources';

const SIDEBAR_WIDTH = 352;

interface Props {
  sx?: SxProps;
  operators?: Operator[];
  teams?: MinifiedTeam[];
  statusCounters?: StatusCounters;
}

const FiltersSidebar: React.FC<Props> = ({ sx, operators, teams, statusCounters }) => {
  const { gridSettings, setGridSettingsItemDebounced, filtersCount, resetAll } = useContext(GridSettingsContext);
  const { statuses } = gridSettings;

  const handleStatusListChange = (items: SelectableItem[]): void => {
    const selectedStatuses = items.filter(({ selected }) => selected).map(({ title }) => title);
    setGridSettingsItemDebounced('statuses', selectedStatuses, true);
  };

  const getFiltersCount = (): number => {
    let count =
      1 + gridSettings.availabilityType.length + gridSettings.availabilityHours.length + gridSettings.statuses.length;
    if (gridSettings.team) {
      count += 1;
    }
    if (gridSettings.operatorId) {
      count += 1;
    }
    return count;
  };

  return (
    <Sidebar
      sx={sx}
      width={SIDEBAR_WIDTH}
      collapseTitle="Hide Filters"
      collapsedContent={
        <Badge badgeContent={getFiltersCount()} color="primary" sx={{ ml: '-6px', mt: '16px' }}>
          <FilterAltIcon color="action" />
        </Badge>
      }
    >
      <Box sx={{ pt: '16px', pb: '64px' }}>
        <Box sx={{ px: '16px', mb: '16px' }}>
          <FiltersCount filtersCount={filtersCount} onResetAll={resetAll} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <GeneralFilters operators={operators} teams={teams} />
          <StatusesFilter statuses={statuses} onChange={handleStatusListChange} statusCounters={statusCounters} />
          <TagsFilter />
          <AdvancedFilters />
          <FiltersBySources />
        </Box>
      </Box>
    </Sidebar>
  );
};

export default FiltersSidebar;
