import React from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { FlexYCenteredCell, SearchInput } from 'components';
import { GridSettingsContext } from 'context';

import ColumnsVisibilitySettings from './columns-visibility-settings';
import { ToolbarContainer, SearchWrapper, ResetStorageButton } from './grid-toolbar.styled';

interface Props {
  columnDefs: GridEnrichedColDef[];
  onBulkAssign: () => void;
  bulkAssignDisabled: boolean;
  onCopy: () => void;
  copyDisabled: boolean;
}

const GridToolbar: React.FC<Props> = ({ columnDefs, onBulkAssign, bulkAssignDisabled, onCopy, copyDisabled }) => {
  const { storeColumnVisibility, setGridSettingsItemDebounced, resetLocalStorage } =
    React.useContext(GridSettingsContext);

  /**
   * removing the column <code>order</code> since it's not a part of dynamic defs
   */
  const buildColumnVisibilitiesDefs = () => {
    const [_, ...dynamicColDefs] = columnDefs;
    return dynamicColDefs;
  };

  return (
    <ToolbarContainer>
      <SearchWrapper>
        <SearchInput
          label="Search"
          variant="outlined"
          size="small"
          sx={{ width: '300px' }}
          color="primary"
          onQueryChange={(query) => setGridSettingsItemDebounced('globalSearch', query, false)}
        />
      </SearchWrapper>
      <FlexYCenteredCell>
        <Button
          sx={{ flexShrink: 0, mr: '8px' }}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onBulkAssign}
          disabled={bulkAssignDisabled}
        >
          Bulk assign
        </Button>
        <Tooltip title="Copy (for Google Sheets)">
          <IconButton size="small" color="primary" sx={{ marginRight: '8px' }} onClick={onCopy} disabled={copyDisabled}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <ResetStorageButton onClick={resetLocalStorage} />
        <ColumnsVisibilitySettings
          sx={{ mr: 2 }}
          columnDefs={buildColumnVisibilitiesDefs()}
          onItemChange={storeColumnVisibility}
        />
      </FlexYCenteredCell>
    </ToolbarContainer>
  );
};

export default GridToolbar;
